import styled from "styled-components";
import tw from "twin.macro";

const Wrapper = styled.div`
  ${tw`
    relative
    flex
    justify-center
    py-4
    md:pt-6
    md:pb-6
    lg:pt-10
    lg:pb-10
    xl:pt-16
    xl:pb-16
    items-center
    flex-col
  `}
`;

const BackGroundContainer = styled.div`
  background-color: #f8f8fa;
  z-index: -1;
  ${tw`
    absolute 
    h-full 
    w-screen 
  `}
`;

const Title = styled.h2`
  ${tw`
    text-2xl
    md:text-3xl
    xl:text-5xl
    font-extrabold
    pb-4
    xl:pb-6
  `}
`;

const SubTitle = styled.h4`
  ${tw`
    text-xl
    md:text-2xl
    xl:text-3xl
    text-yellow-600
    pb-4
  `}
`;

const Description = styled.div`
  font-size: 1.4rem;
  font-style: italic;
  ${tw`
    text-base
    md:text-xl
    lg:text-2xl
    text-justify
    text-gray-dark
  `}
`;

const AboutUsSection = () => {
  return (
    <Wrapper>
      <BackGroundContainer />
      <Title>SOBRE NOSOTROS</Title>
      <SubTitle>Calidad, compromiso y expertise </SubTitle>
      <Description>
        Somos un equipo que resuelve tus tramitaciones. Asesoramos a quienes
        viven en Argentina y fuera del país para brindar la más rápida y segura
        respuesta. Atendemos a las necesidades de cada persona, desde hace
        muchos años. Nuestros profesionales estarán a tu disposición desde el
        momento que nos contactes y te ayudarán a resolver y obtener los
        trámites con eficacia y absoluta reserva.{" "}
      </Description>
    </Wrapper>
  );
};

export default AboutUsSection;
