import styled from "styled-components";
import tw from "twin.macro";

import AboutUsSection from "../sections/aboutUsSection"
import ContactUs from "../sections/contactUs"
import { HeroSection } from "../sections/heroSection"
import OurValues from "../sections/ourValues"
import ServicesSection from "../sections/servicesSection"

const PageContainer = styled.div`
  ${tw`
    w-[95%]
    md:w-[90%]
    m-auto
    pt-28
  `}

`


const Home = () => {
  return (
    <PageContainer>
      <HeroSection />
      <AboutUsSection />
      <ServicesSection />
      <OurValues />
      <ContactUs />
    </PageContainer>
  )
}

export default Home