import { useEffect } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import Aos from "aos";
import 'aos/dist/aos.css'

import Navbar from "./components/header";
import { Footer } from "./components/footer";
import Home from "./pages/Home";

const PageContainer = styled.div`
  overflow: hidden;
`;

const App = ()  => {

  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])

  return (
    <PageContainer>
      <Navbar />
      <Home />
      <Footer />
    </PageContainer>
  );
}
export default App;
