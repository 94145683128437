import React, { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";

import Logo from "../assets/images/logo.svg";
import { SCREENS } from "../utils";

const Nav = styled.div`
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background-color: ${({ backgroundColor, isOpen }) =>
    backgroundColor || isOpen ? "rgba(0, 0, 0, 0.7)" : ""};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
`;

const LogoContainer = styled.a`
  padding: 0 0 0.8em;
  text-decoration: none;
  font-weight: 800;
  font-size: 1.7rem;
  img {
    filter: ${({ backgroundColor, isOpen }) =>
      backgroundColor || isOpen
        ? "brightness(0) saturate(100%) invert(74%) sepia(5%) saturate(1339%) hue-rotate(172deg) brightness(95%) contrast(92%);"
        : ""};
    @media (min-width: ${SCREENS.lg}) {
      width: 3em;
    }
  }
`;

const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  color: ${({ backgroundColor, isOpen }) =>
    backgroundColor || isOpen ? "#fff" : "#023e8a"};

  @media (max-width: ${SCREENS.lg}) {
    overflow: hidden;
    flex-direction: column;
    max-height: ${({ isOpen }) => (isOpen ? "300px" : "0")};
    transition: max-height 0.3s ease-in;
    width: 100%;
  }
`;

const MenuLink = styled.a`
  padding: 1rem 1rem;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease-in;
  &:hover {
    color: #7b7fda;
  }

  @media (max-width: ${SCREENS.xl}) {
    font-size: 1em;
    font-weight: 500;
    padding: 0.5em 0.5em;
  }
`;

const Hamburger = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;
  span {
    height: 2px;
    width: 25px;
    background: #7b7fda;
    margin-bottom: 4px;
    border-radius: 5px;
  }
  @media (max-width: ${SCREENS.lg}) {
    display: flex;
  }
`;

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState(false);

  const changeNavBg = () => {
    window.scrollY >= 90 ? setBackgroundColor(true) : setBackgroundColor(false);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", changeNavBg);
    }
  }, []);

  const handleClick = (e) => {
    e.preventDefault();
    const target = e.target.getAttribute("href");
    const location = document.querySelector(target).offsetTop;
    setIsOpen(!isOpen);

    window.scrollTo({
      left: 0,
      top: location - 102,
    });
  };

  const menuLinks = [
    { id: "tramitaciones", title: "Tramitaciones" },
    { id: "ciudadania", title: "Ciudadanía" },
    { id: "traducciones", title: "Traducciones" },
    { id: "visas", title: "Visas en EEUU" },
    { id: "propiedad", title: "Propiedad" },
    { id: "automotor", title: "Automotor" },
    { id: "jubilaciones", title: "Jubilaciones y Pensiones" },
  ];

  return (
    <Nav isOpen={isOpen} backgroundColor={backgroundColor}>
      <LogoContainer href="" backgroundColor={backgroundColor}>
        <img src={Logo} alt="logo" />
      </LogoContainer>
      <Hamburger onClick={() => setIsOpen(!isOpen)}>
        <span />
        <span />
        <span />
      </Hamburger>
      <Menu isOpen={isOpen} backgroundColor={backgroundColor}>
        {menuLinks.map((link) => (
          <MenuLink key={link.id} href={`#${link.id}`} onClick={handleClick}>
            {link.title}
          </MenuLink>
        ))}
      </Menu>
    </Nav>
  );
};

export default Navbar;
