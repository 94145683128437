export const specialVisas = [
  "Atletas, amateur y profesionales (solamente compitiendo por un premio en dinero)",
  "Atletas, artistas, animadores",
  "Trabajador australiano – especialidad profesional",
  "Tarjeta de Cruce de Frontera: México",
  "Visitantes de negocios",
  "Tripulación (en servicio a bordo de un barco o un avión en los Estados Unidos",
  "Diplomáticos y funcionarios de un gobierno extranjero",
  "Empleados domésticos o niñeras (deben ir acompañando a un empleador extranjero)",
  "Empleados de una organización internacional designada y OTAN",
  "Visitantes de Intercambio",
  "Visitantes de intercambio - au pairs",
  "Visitantes de intercambio – hijos (menores de 21 años) o cónyuge del titular de una visa J-1",
  "Visitantes de intercambio – profesores, investigadores, maestros",
  "Visitantes de intercambio – intercambio cultural",
  "Novio(a)",
  "Personal militar y extranjero emplazados en los Estados Unidos",
  "Ciudadanos extranjeros con habilidad extraordinaria en las ciencias, las artes, educación, negocios o atletismo",
  "Profesionales del Tratado de Libre Comercio (TLC): Chile",
  "Profesionales del Tratado de Libre Comercio (TLC): Singapur",
  "Representante de información de medios de comunicación (medios de comunicación, periodistas)",
  "Transferencia de empleados de una compañía",
  "Tratamiento Médico, visitantes para",
  "Trabajadores profesionalesTLCAN (NAFTA): México, Canadá",
  "Enfermeras que viajan a areas con escasez de profesionales de la salud",
  "Médicos",
  "Trabajadores religiosos",
  "Ocupaciones especializadas en campos que requieren un alto conocimiento especializado",
  "Estudiantes – estudiantes académicos y de idioma",
  "Dependientes de estudiantes – dependiente del titular de una visa F-1",
  "Estudiantes - vocacional",
  "Dependientes de estudiantes – dependiente del titular de una visa M-1",
  "Trabajadores agrícolas temporales – estacionales",
  "Trabajadores temporales – no agrícolas",
  "Visitantes por turismo, vacaciones, placer",
  "Entrenamiento en un programa sin fines de empleo",
  "Inversionistas",
  "Comerciantes",
  "En tránsito en los Estados Unidos",
  "Víctimas del tráfico de personas",
  "Renovaciones de visa en los Estados Unidos - A, G, y OTAN"
];
