import styled from "styled-components";
import tw from "twin.macro";
import { values } from "../utils/data"

const Wrapper = styled.div`
  text-align: center;
  ${tw`
    relative
    flex
    flex-col 
    justify-center
    px-4
    lg:px-0
    py-6
    md:pt-6
    md:pb-6
    lg:pt-10
    lg:pb-10
    xl:pt-16
    xl:pb-16
    items-center
  `}
`;

const BackGroundContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100vw;
  background-color: #f8f8fa;
  z-index: -1;
`;

const Title = styled.h2`
  ${tw`
    text-xl
    md:text-3xl
    lg:text-4xl 
    font-extrabold 
  `}
`;

const Cards = styled.div`
  ${tw`
    mt-4 
    md:mt-6 
    xl:max-w-7xl
    grid 
    grid-cols-1 
    gap-y-4 
    gap-x-6 
    md:gap-6
    xl:gap-y-10 
    sm:grid-cols-2 
    xl:grid-cols-4 
    xl:gap-x-8 
  `}
`;

const Card = styled.div`  
  ${tw`
    bg-white 
    rounded-lg 
    border 
    border-gray 
    shadow-md 
    px-4
    py-2

    `}

  > h5 {
    ${tw`
      mb-2 
      text-lg
      md:text-2xl
      lg:text-2xl 
      font-bold 
      text-[#023E8A] 
      `}
  }

  > p {
    ${tw`
      mb-3
      lg:text-lg
    `}
  }
`;

export default function OurValues() {
  return (
    <Wrapper>
      <BackGroundContainer />
      <Title>POR QUÉ ELEGIRNOS?</Title>
      <Cards>
        {values.map((value, key) => (
          <Card key={key} data-aos="flip-left">
            <h5>{value.title}</h5>
            <p>{value.description}</p>
          </Card>
        ))}
      </Cards>
    </Wrapper>
  );
}
