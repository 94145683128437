import styled from "styled-components";
import tw from "twin.macro";
import { sections } from "../utils/data"; 

const FooterContainer = styled.div`
  background-color: #1d2124;
  ${tw`
    pt-10
    md:pt-16
  `};
`;

const InnerContainer = styled.div`
  justify-content: center;
  margin: auto;
  width: 100%;
  ${tw`
    flex
    h-full
    flex-wrap
  `};
`;

const BottomContainer = styled.div`
  ${tw`
    w-full
    flex
    justify-center
    md:justify-start
    mt-7
    md:mt-1
  `};
`;

const CopyrightText = styled.small`
  font-size: 12px;
  ${tw`
    text-gray
  `}
`;

const SectionContainer = styled.div`
  ${tw`
    w-full
    md:w-auto
    flex
    flex-col
    mr-2
    md:ml-auto
    md:mr-auto
    pl-10
    pr-10
    md:pl-3
    md:pr-3
    mt-7
    md:mt-0
  `};
`;

const LinksList = styled.ul`
  ${tw`
    outline-none
    list-none
    flex
    flex-col
  `};
`;

const ListItem = styled.li`
display: flex;
align-items: center;
  ${tw`
    mb-3
  `};
  & > a {
    ${tw`
      text-sm
    text-white
      transition-all
      hover:text-gray
    `};
  }
`;

const HeaderTitle = styled.h3`
  ${tw`
    text-2xl
    font-bold
    text-white
    mb-3
  `};
`;

const TransparentIcon = styled.span`
display: inline-block;
  ${tw`
    w-7
    h-7
    rounded-full
    text-white
    text-base
    mr-2
  `};

& > a {
    ${tw`
      text-sm
    text-white
      transition-all
      hover:text-gray
    `};
  }
`;

export function Footer() {
  const handleClick = (e) => {
    e.preventDefault()
    const target = e.target.getAttribute('href')
    const location = document.querySelector(target).offsetTop

    window.scrollTo({
      left: 0,
      top: location - 102,
    })
  }

  return (
    <FooterContainer>
      <InnerContainer>
        {sections.map((section, index) => (
          <SectionContainer key={index}>
            <HeaderTitle>{section.title}</HeaderTitle>
            <LinksList>
              {section.links.map((link, idx) => (
                <ListItem key={idx}>
                  {link.image && 
                  <TransparentIcon>
                      <img src={link.image} alt="blob icon" color="white" bgColor="dark" />
                  </TransparentIcon>
                  }
                  <a href={link.href} onClick={(e) => handleClick(e)}>
                    {link.label}
                  </a>
                </ListItem>
              ))}
            </LinksList>
          </SectionContainer>
        ))}
      </InnerContainer>
      <BottomContainer>
        <CopyrightText>
          Copyright &copy; {new Date().getFullYear()}. All rights
          reserved.
        </CopyrightText>
      </BottomContainer>
    </FooterContainer>
  );  
}
