import Procedures from '../assets/images/tramitaciones.png'
import Ciudadanias from '../assets/images/citizenship.png'
import USA from '../assets/images/united-states-flag.png'
import Property from '../assets/images/property.png'
import Automotive from '../assets/images/automotive.png'
import Translations from '../assets/images/translations.png'
import Retirement from '../assets/images/retirement.png'
import Instagram from "../assets/images/instagram.svg";
import Facebook from "../assets/images/facebook.svg";


export const servicesData = [
  {
    id: "tramitaciones",
    image: Procedures,
    title: "TRAMITACIONES",
    alt: "https://unsplash.com/photos/BaSeK7rwc1A",
    text1: `<p>Tramitamos la documentación que necesites</p>
            <ul>
              <li>Certificados de nacimiento, defunción y matrimonio</li>
              <li>Gestionamos en ANSES, AFIP, Registro Automotor y Registro de la Popiedad Inmueble en todo el país</li>
              <li>Tramitamos tu título y analítico de estudios</li>
            </ul>
          `,
    text2: "Gestionamos en ANSES, AFIP, Registro Automotor y Registro de la Propiedad Inmueble en todo el país",
    text3: "Tramitamos tu titulo y analitico de estudios",
  },
  {
    id: "ciudadania",
    image: Ciudadanias,
    title: "CIUDADANÍA",
    alt: "https://unsplash.com/photos/ShNmjHFmv4Q",
    text1: `<p>Querés ser ciudadano extranjero?</p>
            <p>Nuestro equipo te asesora para la obtención de las ciudadanías:</p>
            <ul>
              <li>Italiana</li>
              <li>Española</li>
              <li>Portuguesa</li>
              <li>Argentina</li>
            </ul>
          `,
    text2: "Nuestro equipo te asesora para la obtencion de las ciudadanias:",
    list: {
      item1: "Italiana",
      item2: "Española",
      item3: "Portuguesa",
      item4: "Argentina",
    },
  },
  {
    id: "traducciones",
    image: Translations,
    title: "TRADUCCIONES",
    alt: "https://unsplash.com/photos/ywqa9IZB-dU",
    text1: `<p>Traducción de documentos emitidos por entidades oficiales, gubernamentales o privadas:</p>
    <p>Nuestro equipo te asesora para la obtención de las ciudadanías:</p>
    <ul>
      <li>Certificado de nacimiento, matrimonio y defunción</li>
      <li>Apostilla de la Haya</li>
      <li>Certificados de titulos</li>
      <li>y mas</li>
    </ul>
    <p>Legalizados por traductor público (oficial) matriculado.</p>
    <p>Contamos con un excelente equipo de traductores e intérpretes del idioma que usted requiera/vos necesites.</p>
  `,
  },
  {
    id: "visas",
    image: USA,
    title: "VISAS EN EEUU",
    alt: "https://unsplash.com/photos/lA-wfuq-7CQ",
    text1: `<p>Somos facilitadores de tramitaciones de VISAS DE ESTADOS UNIDOS</p>
    <ul>
      <li>Visas de no inmigrantes</li>
      <li>Visa de Negocios / Turismo</li>
      <li>Visa de Inversión</li>
      <li>Visa de Trabajo</li>
      <li>Visa de Estudiante</li>
      <li>Visa de Visitante en Intercambio</li>
      <li>Visa de Tránsito / Tripulación</li>
      <li>Visa de Periodistas y medios de comunicación</li>
    </ul>
    <p>Somos abogados acreditados en Argentina</p>
  `,

  },
  {
    id: "propiedad",
    image: Property,
    title: "PROPIEDAD",
    alt: "https://unsplash.com/photos/rgJ1J8SDEAY",
    text1: `<p>Necesitas certificados de dominio, inhibición, etc. en el Registro de Propiedad?</p>
            <p>Nosotros podemos tramitarlos.</p>
            <ul>
              <li>Informes sobre propiedades en Capital Federal, GBA y en todo el país</li>
              <li>Informes de inhibición</li>
              <li>Informes de frecuencia</li>
              <li>Certificado de catastro</li>
              <li>Búsqueda de titulares de dominio</li>
              <li>y mas</li>
            </ul>
            `,
  },
  {
    id: "automotor",
    image: Automotive,
    title: "AUTOMOTOR",
    alt: "https://unsplash.com/photos/Q8ERl7LaFGM",
    text1: `<p>Necesitas hacer trámites en el Registro del Automotor?</p>
            <p>Nuestro equipo puede hacerlo por vos y enviártelo</p>
            <ul>
              <li>Informe de dominio y/o histórico</li>
              <li>Cédula verde</li>
              <li>Cédula de poseedor - Autorizado a conducir</li>
              <li>Transferencia automotor</li>
              <li>Transferencia motovehículos</li>
              <li>Duplicado reposición de chapa patente</li>
              <li>Cambio de motor, chasis y carrocería</li>
              <li>Denuncia de venta</li>
              <li>Denuncia de robo y/o hurto</li>
          </ul>
           `,
  },
  {
    id: "jubilaciones",
    image: Retirement,
    title: "JUBILACIONES Y PENSIONES",
    alt: "https://unsplash.com/photos/6D58t6uZT5M",
    text1: `<p>Nuestro equipo realiza trámites de ANSES</p>
   `,
  },
];

export const values = [
  {
    id: 1,
    title: "SEGURIDAD",
    imageSrc:
      "https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg",
    imageAlt: "Front of men's Basic Tee in black.",
    description:
      "Te ofrecemos absoluta confidencialidad a tu consulta y transparencia en la obtención de documentación. Brindamos soluciones con reglas claras y confiables. Motivo por el cual, realizamos los trámites personalmente sin intermediarios",
  },
  {
    id: 2,
    title: "RESPONSABILIDAD",
    imageSrc:
      "https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg",
    imageAlt: "Front of men's Basic Tee in black.",
    description:
      "Formamos un equipo sólido, a lo largo de más de 20 años de experiencia en tramitaciones de documentación. Nuestra marca es el sello que cuidamos para poder brindar respuesta a tus requerimientos. Varios de nosotros somos inmigrantes en otros países, por lo que conocemos la experiencia de vivir en el extranjero.",
  },
  {
    id: 3,
    title: "CONFIANZA",
    imageSrc:
      "https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg",
    imageAlt: "Front of men's Basic Tee in black.",
    description:
      "El personal de cada oficina se esmera para brindarte todo lo que necesitas sin moverte de tu casa, con la confianza y tranquilidad que te mereces.",
  },
  {
    id: 4,
    title: "ATENCIÓN PERSONALIZADA",
    imageSrc:
      "https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg",
    imageAlt: "Front of men's Basic Tee in black.",
    description:
      "Nosotros te escuchamos y buscaremos darte el mejor asesoramiento para que logres lo que necesitas y nos ocupamos personalmente de tramitarlo.",
  },
];

export const sections = [
  {
    title: "Nuestro Sitio",
    links: [
      { label: "Inicio", href: "#" },
      { label: "Tramitaciones", href: "#tramitaciones" },
      { label: "Ciudadanía", href: "#ciudadania" },
      { label: "Traducciones", href: "#traducciones" },
      { label: "Visas en EEUU", href: "#visas" },
      { label: "Automotor", href: "#automotor" },
      { label: "Jubilaciones y pensiones", href: "#jubilaciones" },
    ],
  },
  {
    title: "Llamanos",
    links: [
      { label: "+54911-4374-5803", href: "tel:+5491143745803" },
      { label: "Whatsapp: +54911-6887-3042", href: "tel:+5491168873042" },
    ],
  },
  {
    title: "Mail",
    links: [{ label: "info@asesoresdegestion.com", href: "mailto:info@asesoresdegestion.com" }],
  },
  {
    title: "Redes",
    links: [
      {
        label: "@asesoresdegestiononline",
        href: "https://instagram.com/asesoresdegestiononline?igshid=NTc4MTIwNjQ2YQ==",
        image: Facebook,
      },
      { label: "/asesoresdegestiononline", 
        href: "https://www.facebook.com/asesoresdegestiononline",
        image: Instagram,
      },
    ],
  },
];
