import styled from "styled-components";
import tw from "twin.macro";

import Procedures from "../assets/images/procedures.svg";
import Cityzenship from "../assets/images/citizenship.svg";
import Translations from "../assets/images/translations.svg";
import Visa from "../assets/images/visa.svg";
import Property from "../assets/images/property.svg";
import Automotive from "../assets/images/automotive.svg";
import { SCREENS } from "../utils";
import { useState } from "react";

const StepsContainer = styled.div`
  ${tw`
  flex
  justify-evenly
  flex-wrap
  pt-3
  pb-3
  lg:pt-6
  lg:pb-6
  lg:mt-7
  sm:w-[75%]
  md:w-[80%]
  lg:w-[100%]
  sm:m-auto
  `}
`;

const StepContainer = styled.div`
  ${tw`
  flex
  flex-col
  items-center
  m-3
  `}
`;

const StepTitle = styled.h4`
  ${tw`
  text-black
  lg:text-lg
  font-semibold
  mt-4
  `}
`;


const StepIcon = styled.span`
  background-color: #023E8A;
  box-shadow: 0 1.3px 12px -3px rgba(0, 0, 0, 0.4);
  transition: all 0.35s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
  > img {
    width: 60%;

    @media (min-width: ${SCREENS.md}) {
      width: 60%;
    }

    @media (min-width: ${SCREENS.lg}) {
      width: 80%;
    }
    
    @media (min-width: ${SCREENS.xl}) {
      width: 100%;
    }
  }
  ${tw`
  flex
  rounded-lg
  items-center
  justify-center
  cursor-pointer
  p-2
  lg:p-4
  `}
`;

const stepsData = [
  { image: Procedures, title: "Tramitaciones", id: "tramitaciones" },
  { image: Cityzenship, title: "Ciudadanía", id: "ciudadania" },
  { image: Translations, title: "Traducciones", id: "traducciones" },
  { image: Visa, title: "Visas en EEUU", id: "visas" },
  { image: Property, title: "Propiedad", id: "propiedad" },
  { image: Automotive, title: "Automotor", id: "automotor" },
];

const IconServices = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (e) => {
    e.preventDefault()
    const target = e.target.getAttribute('href')
    const location = document.querySelector(target).offsetTop
    setIsOpen(!isOpen)

    window.scrollTo({
      left: 0,
      top: location - 102,
    })
  }

  return (
    <StepsContainer>
      {stepsData.map((step, index) => (
        <StepContainer key={index} data-aos="fade-up">
          <StepIcon>
            <img src={step.image} alt="blob icon" href={`#${step.id}`} onClick={handleClick} />
          </StepIcon>
          <StepTitle>{step.title}</StepTitle>
        </StepContainer>
      ))}
    </StepsContainer>
  );
}

export default IconServices