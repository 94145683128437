import styled from "styled-components";
import tw from "twin.macro";
import HeroImg from "../assets/images/hero-image.png";
import BlobImg from "../assets/images/blob.svg";
import CheckMark from "../assets/images/check-mark.svg";
import { SCREENS } from "../utils";
import IconServices from "./iconsServicesSection";


const Wrapper = styled.div`
  ${tw`
    // w-full
    // max-w-screen-xl
    // flex
    // flex-col
    // justify-between
    // pl-3
    // pr-3
    // lg:pl-40
    // lg:pr-12
    // pb-2
  `}
`;

const TopSectionContainer = styled.div`

  ${tw`
    flex
    flex-col
    md:flex-row
    items-center
  `}
`;

const LeftContainer = styled.div`
  ${tw`
    md:w-1/2
    relative
    md:ml-8
    lg:ml-12
    xl:ml-24
    xxl:ml-64
    text-center
    md:text-left
  `}
`;

const Slogan = styled.h1`
  ${tw`
    font-bold
    text-4xl
    md:text-4xl
    xl:text-[4rem]
    xxl:text-7xl
    md:font-extrabold
    leading-snug
    md:leading-normal
    xl:leading-relaxed
    xxl:leading-normal 

 `}
`;

const Description = styled.p`
  ${tw`
  lg:text-lg
  pt-2
  lg:pt-0
`}
`;

const ButtonContainer = styled.div`
  ${tw`
    flex
    mt-4
    lg:mt-8
    flex-wrap
    justify-around
    w-full
  `}
`;

const CheckList = styled.div`
  ${tw`
    flex
    flex-wrap
    items-center
  `}

  > img {
    width: 1em;
    margin-right: 0.2em;
  }
`;

const RightContainer = styled.div`
  ${tw`
    w-1/2
    relative
  `}
`;

const BlobContainer = styled.div`
  width: 35em;
  height: 10em;
  position: absolute;
  right: -6em;
  top: -24em;
  z-index: -1;
  transform: rotate(-35deg);
  opacity: 0.6;

  @media (min-width: ${SCREENS.md}) {
    width: 53em;
    max-height: 30em;
    right: -4em;
    top: -9em;
    transform: rotate(-30deg);
    opacity: 1;
  }

  @media (min-width: ${SCREENS.xl}) {
    width: 55em;
    max-height: 30em;
    right: -4em;
    top: -10em;
    transform: rotate(-30deg);
  }
  @media (min-width: ${SCREENS.xxl}) {
    width: 64em;
    max-height: 34em;
    right: -5em;
    top: -13em;
    transform: rotate(-30deg);
  }
`;

const ImageContainer = styled.div`
  display: none;

  img {
    width: auto;
    height: 100%;
    max-width: fit-content;
  }

  @media (min-width: ${SCREENS.sm}) {
    height: 16em;
    padding-right: 3em;
  }

  @media (min-width: ${SCREENS.md}) {
    display: flex;
    justify-content: flex-end;
    height: 14em;
    margin-top: 2em;
  }

  @media (min-width: ${SCREENS.xl}) {
    height: 22em;
    margin-top: 3em;
  }

  @media (min-width: ${SCREENS.xxl}) {
    height: 26em;
    margin-top: 3em;
  }
`;

const BigDotsWrapper = styled.div`
  position: absolute;
  left: -2.5em;
  top: -1em;
`;

const SmallDotsWrapper = styled.div`
  position: absolute;
  left: 30em;
  bottom: 8em;
`;

const HorizontalDotsWrapper = styled.div`
  position: absolute;
  left: 4em;
  top: -3em;
  z-index: 30;
  width: 200px;
`;

export function HeroSection() {
  return (
    <Wrapper>
      <TopSectionContainer>
        <LeftContainer data-aos="fade-right">
          <BigDotsWrapper>
          </BigDotsWrapper>
          <Slogan>
            <h1>GESTIÓN INTERNACIONAL ONLINE</h1>
          </Slogan>
          <SmallDotsWrapper>
          </SmallDotsWrapper>
          <Description>
            Nuestra Misión es que cuentes con un completo servicio de asesoría y
            todo tipo de trámites que necesites sin moverte de tu casa.
          </Description>
          <ButtonContainer>
            <CheckList>
              <img src={CheckMark} alt="blob icon" />
              Rápido
            </CheckList>
            <CheckList>
              <img src={CheckMark} alt="blob icon" />
              Seguro
            </CheckList>
            <CheckList>
              <img src={CheckMark} alt="blob icon" />
              Sin moverte de tu casa
            </CheckList>
          </ButtonContainer>
        </LeftContainer>
        <RightContainer data-aos="fade-left">
          <BlobContainer>
            <img src={BlobImg} alt="blob icon" />
          </BlobContainer>
          <ImageContainer>
            <img src={HeroImg} alt="hero img" />
          </ImageContainer>
          <HorizontalDotsWrapper>
          </HorizontalDotsWrapper>
        </RightContainer>
      </TopSectionContainer>
      <IconServices />
    </Wrapper>
  );
}
