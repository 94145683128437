import { Services } from "../components/services";
import styled from "styled-components";
import tw from "twin.macro";

const Wrapper = styled.div`
  ${tw`
    pt-2
    pb-2
    lg:pt-8
    lg:pb-8
  `}

  h2 {
    text-align: center;
    font-weight: 800;

    ${tw`
    text-xl
    md:text-3xl
    xl:text-5xl
    pt-2
    mb-[-0.5em]
    md:mb-0
    lg:pt-8
    lg:pb-8
  `}
  }

`;


const ServicesSection = () => {
  return (
    <Wrapper>
      <h2>SERVICIOS</h2>
      <Services />
    </Wrapper>
  );
};

export default ServicesSection;
