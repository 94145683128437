import styled from "styled-components";
import tw from "twin.macro";
import { SCREENS } from "../utils";
import Dots from "../assets/images/services-dots.svg";
import { servicesData } from "../utils/data";
import Modal from "../atoms/modal";

const Container = styled.div`
  border-bottom: 2px double;

  @media (min-width: ${SCREENS.md}) {
    border-bottom: none;
  }

  ${tw`
  relative
  flex
  flex-wrap
  flex-col
  md:flex-row
  justify-center
  md:justify-between
  lg:justify-around
  pb-2
  pt-6
  md:pt-4
  md:pb-4
  `}
`;

const CardContainer = styled.div`
  img {
    height: 11em;
    z-index: 2;
    padding-bottom: 1em;

    @media (min-width: ${SCREENS.sm}) {
      height: 14em;
    }
    @media (min-width: ${SCREENS.md}) {
      margin-top: 2em;
    }
    @media (min-width: ${SCREENS.lg}) {
      height: 18.5em;
    }
    @media (min-width: ${SCREENS["2xl"]}) {
      margin-left: 0;
    }
  }

  ${tw`
  flex
  flex-wrap
  justify-center
  `}
`;

const ServicesDots = styled.div`
  position: absolute;
  top: 1em;
  left: 12rem;

  > img {
    height: 100px;
  }

  @media (min-width: ${SCREENS.sm}) {
    top: 8em;
    left: 18em;

    > img {
      height: 140px;
    }
  }
  @media (min-width: ${SCREENS.md}) {
    top: 10em;
    left: 6em;

  }

  @media (min-width: ${SCREENS.lg}) {
    top: 10em;
    left: 6em;

    > img {
      height: auto;
    }
  }
  @media (min-width: ${SCREENS.xl}) {
    top: 10em;
    left: 11em;

    > img {
      height: auto;
    }
  }
  @media (min-width: ${SCREENS.xxl}) {
    top: 10em;
    left: 16em;

    > img {
      height: auto;
    }
  }
`;

const InfoContainer = styled.div`
  ${tw`
  relative
  md:w-1/2
  flex
  flex-col
  justify-between
  `}
`;

const Title = styled.h3`
  border-bottom: 1px solid #023e8a;
  ${tw`
  w-[85%]
  md:w-full
  text-xl
  md:text-2xl
  lg:text-2xl
  xl:text-3xl
  font-extrabold
  md:font-black
  md:leading-normal
  mt-[-0.5em]
  md:mt-0
  `}

  @media (min-width: ${SCREENS.md}) {
    border-bottom: 3px solid #023e8a;
  }
`;

const InfoText = styled.p`
  ${tw`
  md:max-w-2xl
  text-sm
  md:text-base
  xl:text-lg
  text-gray
  font-normal
  mt-2
  md:mt-4
  `}
  p {
    ${tw`
      pb-1
      md:pb-4
    `}
  }

  ul {
    ${tw`
      pl-6
    `}

    > li {
      list-style: circle;
      ${tw`
        pb-2
      `}
    }
  }
`;


const ButtonContainer = styled.div`
  ${tw`
    pb-4
  `}
`;

const Button = styled.a`
  &:hover {
    background-color: #023E8A;
    color: white
  }
`

export function Services() {
  const handleClick = (e) => {
    e.preventDefault();
    const target = e.target.getAttribute("href");
    const location = document.querySelector(target).offsetTop;

    window.scrollTo({
      left: 0,
      top: location - 102,
    });
  };

  return (
    <div>
      {servicesData.map((item, key) => {
        return (
          <Container key={key} id={item.id} data-aos="fade-up">
            <ServicesDots>
              <img src={Dots} alt="dots" />
            </ServicesDots>
            <CardContainer>
              <img src={item.image} alt={item.alt} />
            </CardContainer>
            <InfoContainer>
              <div>
                <Title>{item.title}</Title>
                <InfoText
                  dangerouslySetInnerHTML={{ __html: item.text1 }}
                ></InfoText>
              </div>
              <ButtonContainer>
                <Button
                  href="#contactanos"
                  onClick={handleClick}
                  className="inline-flex items-center mt-4 py-2 px-3 text-sm font-medium text-center bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Contactanos
                  <svg
                    className="ml-2 -mr-1 w-4 h-4"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </Button>
              </ButtonContainer>
            </InfoContainer>

          </Container>
        );
      })}
      <Modal />
    </div>
  );
}
