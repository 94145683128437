import styled from "styled-components";
import tw from "twin.macro";
import emailjs from "emailjs-com";
import { useState } from "react";

const Wrapper = styled.div`
  ${tw`
    xl:w-[80%]
    m-auto
    relative
    flex
    flex-col 
    justify-center
    px-4
    sm:px-12
    md:px-6
    lg:px-0
    py-6
    md:pt-6
    md:pb-6
    lg:pt-10
    lg:pb-10
    xl:pt-16
    xl:pb-16
    items-center
  `}
`;

const Title = styled.h2`
  ${tw`
    text-xl
    md:text-3xl
    lg:text-4xl 
    font-extrabold 
  `}
`;

const Fields = styled.div`
  ${tw`
    flex 
    flex-wrap 
    mt-2
    md:pb-4
  `}
`;

const Field = styled.div`
  ${tw`
    w-full
    md:w-1/2 
    mb-2
    md:px-4
  `}
`;

const Label = styled.label`
  ${tw`
    text-[#023E8A] 
    text-xs
    sm:text-[0.8em]
    font-bold
    pl-2

  `}
`;

const Input = styled.input`
  ${tw`
    appearance-none 
    w-full
    bg-gray-light 
    border 
    border-gray 
    rounded 
    py-3 
    px-4 
    focus:outline-none 
    focus:bg-white 
    focus:border-[#023E8A]
    focus:border-2	
  `}
`;

const MessagField = styled.div`
  ${tw`
    w-full 
    md:px-4
  `}

  > textarea {
    ${tw`
      resize-none
      appearance-none
      w-full 
      bg-gray-light 
      border 
      border-gray 
      rounded 
      py-3 
      px-4 
      mb-3 
      focus:outline-none 
      focus:bg-white 
      focus:border-gray 
      h-48 
  `}
  }
`;

const ButtonContainer = styled.div`
  ${tw`
    text-center
  `}
`;

const Button = styled.button`
  ${tw`
    w-1/2
    md:text-lg
    shadow 
    bg-[#023E8A] 
    hover:bg-[rgba(2, 62, 138, 0.6)] 
    hover:text-black 
    focus:shadow-inner 
    focus:outline-none 
    text-white 
    font-bold 
    py-2 
    md:py-4 
    px-4 
    rounded
  `}
`;

const Message = styled.div`
  ${tw`
    pt-4
    text-xl
  `}
`;

const ContactUs = () => {
  const [message, setMessage] = useState(null);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "asesores_email",
        "asesores_de_gestion",
        e.target,
        "user_CLlpYlJ5sKkXQ4MOclSOH"
      )
      .then(
        (result) => {
          setMessage("Su mensaje ha sido enviado. Gracias");
          console.log(result.text);
        },
        (error) => {
          setMessage("Su mensaje no pudo ser enviado. Intente otra vez");
          console.log(error.text);
        }
      );

    e.target.reset();
  };
  return (
    <div>
      <Wrapper id="contactanos" offset='140'>
        <Title>CONTACTANOS</Title>
        <form onSubmit={sendEmail}>
          <Fields>
            <Field>
              <Label htmlFor="grid-first-name">NOMBRE</Label>
              <Input
                id="grid-first-name"
                type="text"
                name="name"
                placeholder="Nombre"
                required 
                
              />
            </Field>
            <Field>
              <Label htmlFor="grid-last-name">TELÉFONO</Label>
              <Input
                id="grid-last-name"
                type="text"
                name="phone"
                placeholder="Tel"
                required
              />
            </Field>
            <Field>
              <Label htmlFor="grid-password">E-MAIL</Label>
              <Input
                id="email"
                type="email"
                name="email"
                placeholder="E-mail"
                required
              />
            </Field>
            <Field>
              <Label htmlFor="grid-password">EMPRESA</Label>
              <Input
                id="company"
                type="text"
                name="company"
                placeholder="Empresa"
              />
            </Field>
          </Fields>
          <MessagField>
            <Label htmlFor="grid-password">MENSAJE</Label>
            <textarea
              id="message"
              type="text"
              name="message"
              required
              placeholder="Me comunico por..."
            ></textarea>
          </MessagField>
          <ButtonContainer>
            <Button type="submit">Enviar</Button>
          </ButtonContainer>
        </form>
        <Message>
        {message && <p>{message}</p>}
        </Message>
      </Wrapper>
    </div>
  );
};

export default ContactUs;
